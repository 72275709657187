/* Sidebar Container */
.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(237, 244, 237, 1);
    box-shadow: 1px 0px 5px rgb(182, 182, 182);
    /* padding: 20px; */
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1000;
    overflow-y: auto;
    transition: width 0.3s ease-in-out;
  }
  
  /* Open and Collapsed Sidebar States */
  .sidebar.open {
    width: 20vw;
  }
  
  .sidebar.collapsed {
    width: 0px;
    padding: 0px;
  }
  
  /* Toggle Button Styling */
  .toggle-button {
    position: fixed; /* Keep the button in a fixed position */
    left: 0.5em; /* Adjust so it doesn’t shift with sidebar width */
    top: 0.5em;
    width: 1.5em;
    height: 1.5em;
    background-color: #79B791;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    z-index: 1100; /* Ensure button overlays sidebar */
  }
  
  /* Toggle Button Hover Effect */
  .toggle-button:hover {
    background-color: #659979;
  }
  
  /* Sidebar Content */
  .sidebar h3 {
    margin-top: 0;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .report-stats {
    font-size: 28px;
  }